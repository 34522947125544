import React from "react";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import mastercardImg from "images/mastercard.svg";
import visaImg from "images/visa.svg";
import stripeImg from "images/stripe.svg";
import { COLORS } from "config/theme";
import { useHistory } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Au Cuir Du Lotus",
    menus: [
      { href: "#", label: "8 Rue Isabelle Autissier, 17730 Port-des-Barques" },
      { href: "#", label: "Nous contacter: 05 63 67 88 26" },
      { href: "mailto:commercial@au-cuir-du-lotus.fr", label: "commercial@au-cuir-du-lotus.fr" },
    ],
  },
  {
    id: "2",
    title: "Nos catégories",
    menus: [
      { href: "/products/", label: "Maroquinerie", id: '64f06075548de718782e689f' },
      { href: "/products/", label: " Petite Maroquinerie", id: '64f0608a548de718782e68a0'},
      { href: "/products/", label: "Ceinture", id: '64f06097548de718782e68a1' },
      { href: "/products/", label: " Accessoires", id: '64f060ab548de718782e68a2' },
    ],
  },
  {
    id: "3",
    title: "Légal / Contact",
    menus: [
      { href: "/cgv", label: "CGV" },
      { href: "/mentions-legales", label: "Mentions légales" },
      { href: "/contact", label: "Nous contacter" },
    ],
  },
];

const Footer: React.FC = () => {
  const history = useHistory();

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {

    
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="">
          {menu.menus.map((item, index) => (
            <li key={index} className="xs:mt-2 lg:mt-1">
              <a
                key={index}
                className={`cursor-pointer hover:underline font-[400] dark:text-neutral-300`}
                onClick={() => { menu?.id === "2" && history.push({ pathname: item?.href, search: `?categories=${item?.label}` });  menu?.id === "3" && history.push({ pathname: item?.href }); }}
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>

      <div className={`nc-Footer relative py-10 lg:pb-24`} style={{ backgroundColor: COLORS.footer, color: COLORS?.footerText }}>


        <div className="container flex lg:flex-row xs:flex-col justify-between">
          {widgetMenus.map(renderWidgetMenuItem)}
        </div>

        <div className="container mt-[35px]">

          <div className="border-t border-[#BFC6DB] ">
            <div className="mt-[25px] flex grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 grid-cols-1 justify-between">

              <div>
                <h1 className="text-[13px] mt-[3px]">
                  Services E-commerce développé par <a onClick={() => window.open('https://milango.io/')} className="underline hover:text-[#f90]" href="https://milango.io/">Milango</a>
                </h1>
              </div>

              <div className="flex flex-row lg:justify-end md:justify-end xl:justify-end">
                <h1 className="text-[13px] mt-[4px]">
                  Paiement sécurisé :
                </h1>

                <img width={60} className="ml-[8px]" src={stripeImg} />
                <img width={45} className="ml-[4px]" src={mastercardImg} />
                <img width={45} className="ml-[5px]" src={visaImg} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Disclosure } from "@headlessui/react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { getAllCategories, getAllCategoriesFlat } from "actions/categories";
import { getAllFilter, getAllProductsFilter } from "actions/products";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface FilterMobileProps {
  data?: NavItemType[];
  onClickClose: () => void;
}

const FilterMobile: React.FC<FilterMobileProps> = ({
  data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {
  const history = useHistory();
  const location = useLocation()
  const [categories, setCategories] = useState([]);
  const [categoriesFlat, setCategoriesFlat] = useState([]);
  const [isOpen, setIsOpen] = useState(true); // État local pour suivre l'état de visibilité
  const [arrCollapseCat, setArrCollapseCat]: any = useState([]);
  const [filter, setFilter]: any = useState()
  const [loading, setLoading] = useState(true); // État local pour le chargement
  const [isVisable, setIsVisable] = useState(false);
  const [productCat, setProductCat] = useState([]);
  const [allFilters, setAllFilters]: any = useState([]);

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(res)
    })
    getAllCategoriesFlat().then((res) => {
      setCategoriesFlat(res)
    })
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const categoryParams = queryParams.get("categories");


    if (categoryParams) {
      const selectedCategories = categoryParams.split(";"); // Assuming categories are separated by semicolons
      setFilter({ ...filter, categories: selectedCategories });
    } else {
      setFilter({ ...filter, categories: [] });
    }
  }, [location.search]);

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(res)
    })
    getAllCategoriesFlat().then((res) => {
      setCategoriesFlat(res)
    })
  }, [])

  useEffect(() => {

    const categoryNames = filter?.categories; // Tableau de noms de catégories

    // Créer un tableau d'ID de catégories à filtrer
    getAllCategoriesFlat().then((resFlat) => {

      const categoryIdsToFilter = categoryNames?.map((categoryName: any) => {
        const category: any = resFlat.find((cat: any) => cat.name === categoryName);
        return category ? category._id : null;
      }).filter((categoryId: any) => categoryId !== null); // Filtre les ID null (catégories non trouvées)

      if (categoryIdsToFilter) {
        getAllProductsFilter({ categories: categoryIdsToFilter }).then((res) => {
          setProductCat(res?.result);
          setLoading(false); // Fin du chargement

          const arrProductId: any = [];

          res?.result?.map((it: any) => {
            arrProductId.push(it?._id)
          })

          getAllFilter({ "productId": arrProductId }).then((res) => {
            setAllFilters(res)
          })
        })
      }
      setCategoriesFlat(resFlat)
    })
  }, [filter]);

  useEffect(() => {

    const categoryNames = filter?.categories; // Tableau de noms de catégories

    // Créer un tableau d'ID de catégories à filtrer
    getAllCategoriesFlat().then((resFlat) => {

      const categoryIdsToFilter = categoryNames?.map((categoryName: any) => {
        const category: any = resFlat.find((cat: any) => cat.name === categoryName);
        return category ? category._id : null;
      }).filter((categoryId: any) => categoryId !== null); // Filtre les ID null (catégories non trouvées)

      if (categoryIdsToFilter) {
        getAllProductsFilter({ categories: categoryIdsToFilter }).then((res) => {
          setProductCat(res?.result);
          setLoading(false); // Fin du chargement

          const arrProductId: any = [];

          res?.result?.map((it: any) => {
            arrProductId.push(it?._id)
          })

          getAllFilter({ "productId": arrProductId }).then((res) => {
            setAllFilters(res)
          })
        })
      }
      setCategoriesFlat(resFlat)
    })
  }, [filter]);


  const handleRedirect = (id: any) => {
    if (id) {
      onClickClose()
      history.push({ pathname: '/products/', state: { id: id } })
    }
  }

  const _renderMenuChild = (
    item: any,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.subCat?.map((i: any, index: number) => (
          <Disclosure key={i?._id} as="li" defaultOpen={true}>
            <NavLink
              exact
              strict
              to={{
                state: { id: i?._id }
              }}
              onClick={() => handleRedirect(i?._id)}
              className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
              activeClassName="text-secondary"
            >
              <span
                className={`py-2.5 ${!i.subCat ? "block w-full" : ""}`}
              >
                {i.name}
              </span>
              {i.subCat && (
                <span
                  className="flex items-center flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    {Object.keys(i?.subCat).length !== 0 &&
                      <ChevronDownIcon
                        className="ml-2 h-4 w-4 text-slate-500"
                        aria-hidden="true"
                      />
                    }
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.subCat && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 dark:text-slate-400 "
                )}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const handleChangeCategorie = (name: string) => {
    if (name === 'all') {
      setFilter({ categories: [] });
      updateURL({}); // Clear all filters
    } else {
      const category = categoriesFlat.find((cat: any) => cat.name === name);

      if (!category) {
        return; // Category not found, handle this case as needed
      }

      const newCategories = filter.categories.includes(name)
        ? filter.categories.filter((catName: string) => catName !== name)
        : [...filter.categories, name];

      setFilter({ ...filter, categories: newCategories });

      // Update URL here, but remove everything after "products"
      updateURL({ categories: newCategories }, true);
    }
  };

  const updateURL = (newFilters: any, removeAfterProducts = false) => {
    const queryParams = new URLSearchParams();

    Object.keys(newFilters).forEach((key) => {
      if (Array.isArray(newFilters[key])) {
        const values = newFilters[key].join(';');
        queryParams.append(key, values);
      }
    });

    let newPath = '/products';
    if (removeAfterProducts) {
      newPath += location.pathname.substring(
        location.pathname.indexOf('/products') + '/products'.length
      );
    }

    history.push({
      pathname: newPath,
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Positionne l'utilisateur en haut de la page à chaque changement de route
  }, [history.location.pathname]);

  const renderCategories = (item: any) => Object.keys(item).map((it, index) => {
    const hasSubCat = Array.isArray(item[it]?.subCat) && item[it]?.subCat.length > 0;

    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div key={index}>
        <ul>
          <li className="flex items-center mb-2">
            {item[it]?.name !== "Mes créations" &&
              <>
                {hasSubCat && (
                  <svg
                    onClick={() => toggleOpen()}
                    className={`w-[22px] relative top-[.1rem] arrow-icon cursor-pointer ${arrCollapseCat.indexOf(item[it]._id) !== -1 && "arrow-sub-menu"
                      }`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                )}

                <input
                  type="checkbox"
                  name={item[it].name}
                  id={item[it].name}
                  checked={filter.categories.includes(item[it]?.name)}
                  onChange={() => handleChangeCategorie(item[it]?.name)}
                  className="focus:ring-action-primary p-2 text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 mt-1"
                />

                <label htmlFor={item[it].name} className="ml-2 text-md">
                  {item[it]?.name}
                </label>
              </>
            }
          </li>

          {hasSubCat && isOpen && (
            <li className=''>{renderCategories(Object.assign({}, item[it]?.subCat))}</li>
          )}
        </ul>
      </div>
    );
  });

  return (
    <div className="overflow-y-auto w-full z-[999] h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-2 px-5">
        <h1 className="text-2xl">Filtres</h1>
        <div className="absolute right-16 top-3 p-1">
          <span onClick={() => { setFilter({ categories: [] }); updateURL({}) }}
            className="text-indigo-500 font-semibold cursor-pointer text-[white] text-md rounded-lg">Réinitialiser</span>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>


      <div className="px-4 py-4">

        {/* FILTERS */}
        <h3 className=" mb-4 font-semibold text-md">Catégories</h3>

        <div className="text-md">
          <input
            type={'checkbox'}
            name={'all'}
            id={'all'}
            checked={filter?.categories.length === 0}
            onChange={() => { setFilter({ categories: [] }); updateURL({}); onClickClose() }}
            className={`focus:ring-action-primary p-2 text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500`}
          />

          <label htmlFor="all" className="ml-2 relative top-[2px]">Tout</label>
        </div>

        {renderCategories(categories)}

        <hr className="mt-6" />
      </div>

      <div className="mt-4 border-none flex justify-center">

        <a onClick={onClickClose} className="mt-3 mr-3 text-indigo-600 cursor-pointer">Annuler</a>
        <ButtonPrimary onClick={onClickClose} className="ml-3">Appliquer</ButtonPrimary>
      </div>



    </div>
  );
};

export default FilterMobile;

import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import { getAllProducts } from "actions/products";
import { useState, useEffect } from "react";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import { useHistory } from "react-router-dom";

const PageHome = () => {
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    getAllProducts().then((res: any) => {
      setAllProducts(res)
    })

  }, [])

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Au Cuir Du Lotus, la maroquinerie d'art au Pays Basque</title>
      </Helmet>

      {/* CAROUSSEL  */}
      <SectionHero />

      {/* SECTION HOW IT WORK */}
      <SectionHowItWork />

      <div>
        {/* SLIDER ALL PRODUCTS */}
        {allProducts.length !== 0 &&
          <SectionSliderProductCard
            heading="Les créations du moment"
            data={allProducts}
          />
        }
      </div>

      <div className="main-content-wrapper">
        <div className="bg-[#dacdc5] p-20 max-w-[500px]">
          <h1 className="font-marck text-[60px] text-[#7c6555] leading-[55px] text-center">Au Cuir du Lotus</h1>
          <p className="font-merriweather flex justify-center mt-4">Créatrice de maroquinerie d'Art, je réalise intégralement mes créations en cuir, à la main, dans mon atelier. C'est donc avec plaisir que je vous invite à découvrir et à commander en ligne mes sacs, porte-monnaies et accessoires.</p>
          <h3 className="font-marck text-right text-[40px] text-[#7c6555] mt-4">Sabine</h3>
        </div>
      </div>

    </div>
  );
}

export default PageHome;

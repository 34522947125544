
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import img1 from '../../images/Etape Rabat Sac Floral.png'
import img2 from '../../images/Camille roses.png'
import img3 from '../../images/Montage Rabat Sac Lou.png'
import ButtonPrimary from "shared/Button/ButtonPrimary";

const CreationsPersonnalisees = () => {
    const history = useHistory();
    
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Votre maroquinerie personnalisée - Au Cuir Du Lotus</title>
            </Helmet>

            <div className="bg-[#edebe2] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16 text-[white]">
                    <Link to="/" className="underline cursor-pointer font-[500] text-[#7c6555]">Accueil</Link>
                    <span className="ml-2 mr-2 text-[#555]">{">"}</span>
                    <h1 className="text-[#555]">Créations Personnalisées</h1>
                </div>
            </div>
            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-14">

                            <div className="flex justify-center">
                                <h1 className="font-marck text-[#725E39] font-[500] text-[48px]">Créations Personnalisées</h1>
                            </div>


                            <div className="grid grid-cols-1 md:grid-cols-3 gap-10 lg:grid-cols-3 xl:grid-cols-3 mt-16">
                                <img className='flex h-full w-full' src={img1} />
                                <img className='flex h-full w-full' src={img2} />
                                <img className='flex h-full w-full' src={img3} />
                            </div>
                            
                            <p className="text-[18px] leading-[28px] mt-6 font-merriweather text-[#555] font-[500]">En plus de mes <span className="underline text-[#7c6555] cursor-pointer hover:font-[700]">modèles faits-mains</span>, disponibles dans la partie boutique, je réalise également des créations personnalisées.</p>
                            <p className="text-[18px] leading-[28px] mt-4 font-merriweather text-[#555] font-[500]">Que ce soit une personnalisation sur une de mes créations ou bien une réalisation 100% sur mesure, je m'adapte à votre besoin.<br />Contactez-moi afin que nous puissions échanger sur vos envies.</p>
                            <div className="flex justify-center mt-6">
                                <ButtonPrimary className="font-merriweather" onClick={() => history.push({ pathname: '/contact' })}>Contactez-moi</ButtonPrimary>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CreationsPersonnalisees;

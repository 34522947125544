import { FC, useState } from "react";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import { forgotPasswordMail, resetPassword } from "actions/auth";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';

const PageForgotPswd: FC = () => {
    const history = useHistory()
    const location: any = useLocation();
    const params: { id: string } = useParams();
    const [email, setEmail] = useState(location.state ? location.state.email : '');
    const [password, setPassword] = useState('');
    const [rptPassword, setRptPassword] = useState('');

    const handleForgotPassword = () => {
        let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (email === '' || res.test(email) === false) {
            NotificationManager.error("Merci de bien vouloir renseigner une adresse e-mail !", '')
        } else {

            forgotPasswordMail(email).then(() => {
                NotificationManager.success("Un email avec un lien de rénitialisation vient de vous être envoyer sur l'adresse e-mail renseigné !", '')
            })

        }
    }

    const handleSubmitResetPassword = () => {
        if (password === rptPassword) {
            resetPassword(password, params.id).then(() => {
                NotificationManager.success("Votre mot de passe a été modifier avec succès !", '')
            }).catch((err: any) => {
                NotificationManager.error(err.response.data.error, '')
            })
        } else {
            NotificationManager.error('Les mots de passe doivent être identiques !', '')
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            handleForgotPassword();
        }
    };

    const handleKey2Down = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            handleSubmitResetPassword();
        }
    };

    return (
        <div className="flex container justify-center py-36">
            <Helmet>
                <title>Mot de passe oublié | Au Cuir Du Lotus.fr</title>
            </Helmet>
            <div className="max-w-md mx-auto">
                {/* HEADING */}
                <h2 className="text-2xl sm:text-3xl font-semibold">
                    Rénitialiser votre mot de passe
                </h2>

                <p className="text-slate-500 text-[15px] opacity-[.8] mt-2">
                    Saisissez l'adresse e-mail associé à votre compte dans le champ ci-dessous.
                </p>

                {!params.id &&
                    <div>
                        <div className="max-w-xl space-y-4 mt-8">
                            <div>
                                <Label>Adresse e-mail</Label>
                                <Input onKeyDown={handleKeyDown} defaultValue={location.state ? location.state.email : email} onChange={(e) => setEmail(e.target.value)} placeholder={'Saisissez votre e-mail'} type="text" className="mt-1.5" />
                            </div>

                            <div className="flex flex-row justify-between">
                                <div onClick={() => history.push({ pathname: '/', state: { email: email } })} className="flex mt-2 justify-start cursor-pointer text-center hover:underline text-sm text-green-600">
                                    ← Revenir à l'accueil
                                </div>

                                <div className="flex justify-end">
                                    <ButtonPrimary onClick={() => handleForgotPassword()}>Rénitialiser</ButtonPrimary>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {params.id &&
                    <div>

                        <div className="max-w-xl space-y-6">
                            <div>
                                <Label>Nouveau mot de passe</Label>
                                <Input onKeyDown={handleKey2Down} onChange={(e) => setPassword(e.target.value)} placeholder={'**********'} type="password" className="mt-1.5" />
                            </div>
                            <div>
                                <Label>Répéter mot de passe</Label>
                                <Input onKeyDown={handleKey2Down} onChange={(e) => setRptPassword(e.target.value)} placeholder={'**********'} type="password" className="mt-1.5" />
                            </div>
                            <div onClick={() => handleSubmitResetPassword()} className="pt-2 flex justify-end">
                                <ButtonPrimary>Rénitialiser</ButtonPrimary>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
};

export default PageForgotPswd;

import { Helmet } from "react-helmet";
import bo from "images/pampam.png";
import facebook from "images/facebook.png";
import instagram from "images/instagram.png"
import { Link } from "react-router-dom";

const Contact = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Me contacter - Au Cuir Du Lotus</title>
            </Helmet>

            <div className="bg-[#edebe2] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16">
                    <Link to="/" className="underline cursor-pointer font-[500] text-[#7c6555]">Accueil</Link>
                    <span className="ml-2 mr-2 text-[#555]">{">"}</span>
                    <h1 className="text-[#555]">Nous contacter</h1>
                </div>
            </div>

            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 justify-center flex-col py-8 gap-8">
                            <div className="hidden md:block lg:block xl:block">
                                <img src={bo} />
                            </div>

                            <div className="col-span-2 flex items-center">
                                <div className="w-full">

                                    <h1 className="text-[48px] font-marck text-[#7c6555]">Me contacter</h1>
                                    <p className="mt-10 font-merriweather text-[18px] text-[#555] font-[500]">Vous avez besoin d'un renseignement ou vous avez un projet à me soumettre ?</p>

                                    <div className="mt-6 font-merriweather text-[18px] text-[#555]">
                                        <h2>Nous contacter :</h2>
                                        <div className="">
                                            <a href="tel:+33650838794" className="font-[600] mr-2 cursor-pointer hover:underline hover:font-[600] text-[#7c6555]">06 50 83 87 94</a>
                                             // 
                                            <a href="mailto:commercial@au-cuir-du-lotus.fr" className="font-[600] ml-2 cursor-pointer hover:underline hover:font-[600] text-[#7c6555]">commercial@au-cuir-du-lotus.fr</a>
                                        </div>
                                    </div>

                                    <div className="flex flex-row mt-6 font-merriweather text-[18px] text-[#7c6555]">
                                        <img className="flex mt-1 mr-2 h-full" src={facebook} />
                                        <a href="https://www.facebook.com/Aucuirdulotus" target="NO_BLANK" className="font-[600] cursor-pointer hover:underline hover:font-[700]">Aucuirdulotus</a>
                                    </div>

                                    <div className="flex flex-row mt-6 font-merriweather text-[18px] text-[#7c6555] mb-14">
                                        <img className="flex mt-1 mr-2 h-full" src={instagram} />
                                        <a href="https://www.instagram.com/au_cuir_du_lotus/" target="NO_BLANK" className="font-[600] cursor-pointer hover:underline hover:font-[700]">au_cuir_du_lotus</a>
                                    </div>

                                    <iframe width={'100%'} height={'500px'} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11097.250438932013!2d-1.0737272!3d45.9450368!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x881371b29a3abcbb%3A0x9b6cc1023e3a03d!2sAu%20Cuir%20Du%20Lotus!5e0!3m2!1sfr!2sfr!4v1693397523822!5m2!1sfr!2sfr" loading="lazy"></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Contact;

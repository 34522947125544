import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { getAllCategories } from "actions/categories";
import { getAllFilter, getAllProductsFilter } from "actions/products";
import ProductCard from "components/ProductCard";
import { COLORS } from "config/theme";
import { FC, Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";

export interface PageSearchProps {
  className?: string;
}

const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
  const location: any = useLocation();
  const [query, setQuery] = useState(location?.search.split("?q=")[1]);
  const [products, setProducts] = useState([]);
  const [refreshCounter, setRefreshCounter]: any = useState(0);
  const [categories, setCategories] = useState([]);
  const [allFilters, setAllFilters]: any = useState([]);
  const [filter, setFilter]: any = useState({ "categories": [], 'name': location?.search.split("?q=")[1], 'priceRange': [1, 500] })
  const [sortOrderStates, setSortOrderStates] = useState<string>("");
  const [priceRangeDisplay, setPriceRangeDisplay] = useState([1, 500]);

  const DATA_sortOrderRadios = [
    { name: "Most Popular", id: "Most-Popular" },
    { name: "Best Rating", id: "Best-Rating" },
    { name: "Newest", id: "Newest" },
    { name: "Price Low - Hight", id: "Price-low-hight" },
    { name: "Price Hight - Low", id: "Price-hight-low" },
  ];

  useEffect(() => {
    getAllCategories().then((res) => {
      setCategories(res)
    })
  }, [])

  useEffect(() => {
    if (query)
      handleSearch()
  }, [query])

  useEffect(() => {

    getAllProductsFilter(filter).then((res: any) => {
      setProducts(res?.result);

      const arrProductId: any = [];

      res?.result.map((it: any) => {
        arrProductId.push(it?._id)
      })

      getAllFilter({ "productId": arrProductId }).then((res) => {
        setAllFilters(res)
      })
    })

  }, [filter])

  const handleSearch = () => {
    setFilter({ ...filter, "name": query })
  }

  const renderTabsPriceRage = () => {
    return (
      <div className="relative flex flex-col py-6 space-y-5 pr-3">
        <div className="space-y-5">
          <span className="font-semibold">Prix</span>
        </div>

        <div className="flex justify-between space-x-5">
          <div>
            <label
              htmlFor="minPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Prix min
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                €
              </span>
              <input
                type="text"
                onChange={(e: any) => {
                  setFilter({ ...filter, "priceRange": [Number(e?.target?.value), filter?.priceRange[1]] }); setPriceRangeDisplay([Number(e?.target?.value), filter?.priceRange[1]]);
                }}
                name="minPrice"
                id="minPrice"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={priceRangeDisplay[0]}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Prix max
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                €
              </span>
              <input
                type="text"
                onChange={(e: any) => { setFilter({ ...filter, "priceRange": [filter?.priceRange[0], Number(e?.target?.value)] }); setPriceRangeDisplay([filter?.priceRange[0], Number(e?.target?.value)]) }}
                name="maxPrice"
                id="maxPrice"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={priceRangeDisplay[1]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleChangeCategorie = (id: any) => {
    if (filter?.categories.includes(id)) {
      const newCategories = filter.categories.filter((catId: any) => catId !== id);
      setFilter({ ...filter, categories: newCategories });
    } else {
      setFilter({ ...filter, categories: [...filter.categories, id] });
    }
  };

  const handleChooseFilter = (item: any, it: any) => {
    if (Array.isArray(filter[item]) && filter[item].includes(it)) {
      const newItem = filter[item].filter((catId: any) => catId !== it);
      setFilter({ ...filter, [item]: newItem });
    } else {
      if (Array.isArray(filter[item])) {
        setFilter({ ...filter, [item]: [...filter[item], it] });
      } else {
        setFilter({ ...filter, [item]: [it] });
      }
    }
  };

  const renderCategories = (item: any) => Object.keys(item).map((it, index) => {
    return (
      <div key={index}>
        <ul>
          <div className="flex row">
            <svg className={`w-6 h-6 relative top-[.1rem] cursor-pointer`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
            </svg>

            <input
              type={'checkbox'}
              name={item[it].name}
              id={item[it].name}
              onChange={() => handleChangeCategorie(item[it]?._id)}
              className={`focus:ring-action-primary text-primary-500 p-2 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500 mt-1`}
            />

            <label htmlFor={item[it].name} className="ml-2 text-[15px] mt-[.1rem]">{item[it]?.name}</label>

          </div>

          <div className="ml-[1.1rem] mt-2">
            {renderCategories(Object.assign({}, item[it]?.subCat))}
          </div>

        </ul>
      </div>
    )
  })

  const renderTabsSortOrder = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm  focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!sortOrderStates.length
                  ? `!border-primary-500 bg-[${COLORS?.filterBtn}] text-[white]`
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >

              <span className="ml-2">
                {sortOrderStates
                  ? DATA_sortOrderRadios.filter(
                    (i) => i.id === sortOrderStates
                  )[0].name
                  : "Prix décroissant"}
              </span>
              {!sortOrderStates.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setSortOrderStates("")}>
                  <span className="flex-shrink-0 w-4 h-4 rounded-full bg-[white] text-white flex items-center justify-center ml-3 cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3"
                      viewBox="0 0 20 20"
                      fill={COLORS?.filterBtn}
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {DATA_sortOrderRadios.map((item) => (
                      <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        label={item.name}
                        defaultChecked={sortOrderStates === item.id}
                        onChange={setSortOrderStates}
                      />
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSortOrderStates("");
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Search || Ciseco Ecommerce Template</title>
      </Helmet>

      <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      />
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <div className="relative w-full">
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                type="search"
                value={query}
                onChange={(e: any) => setQuery(e.target.value)}
                placeholder="Type your keywords"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
              />
              <ButtonCircle
                onClick={(e) => handleSearch()}
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
          </div>
        </header>
      </div>



      <div className="container lg:pb-20 lg:pt-20 lg:space-y-8">

        <div className="pb-5 flex justify-between">
          <div className="flex flex-row">
            <div>
              <span className="mr-20 font-[500]">Filtres</span>
              <span onClick={() => { setFilter({ categories: [], 'priceRange': [1, 500] }); setPriceRangeDisplay([1, 500]) }} className="cursor-pointer hover:underline text-[#7c6555] font-[500]">Réinitialiser</span>
            </div>

            <div className="h-5 border-l border-slate-300 dark:border-slate-700 mr-6 ml-6"></div>
            <div>
              <span className="font-[500]">{products.length} produits</span>
            </div>
          </div>
          <div>
            {renderTabsSortOrder()}
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700" />

        <main>
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/3 xl:w-1/4 pr-4">
              <div className="relative flex flex-col pb-6 border-[1px] border-[transparent] border-b-[#e2e8f0] pt-8">

                {/* FILTERS */}
                <h3 className="font-semibold mb-6">Catégories</h3>

                <div className="mb-2">
                  <input
                    type={'checkbox'}
                    name={'all'}
                    id={'all'}
                    onChange={() => setFilter({ categories: [] })}
                    className={`focus:ring-action-primary p-2 text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500`}
                  />

                  <label htmlFor="all" className="ml-2 text-[15px] relative top-[2px]">Tout</label>
                </div>

                {renderCategories(categories)}
              </div>

              <>
                {Object.keys(allFilters).map((item: any, index: number) => (
                  <div key={index} className="relative flex flex-col pb-6 border-[1px] border-[transparent] border-b-[#e2e8f0] pt-8">

                    {/* FILTERS */}
                    <h3 className="font-semibold mb-6 capitalize">{item}</h3>

                    {allFilters[item].map((it: any, index: number) => (
                      <div key={index} className="mb-2">
                        <input
                          type={'checkbox'}
                          name={it}
                          id={it}
                          checked={filter[item]?.includes(it)}
                          onChange={() => handleChooseFilter(item, it)}
                          className={`focus:ring-action-primary p-2 text-primary-500 rounded border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-500`}
                        />

                        <label htmlFor="all" className="ml-2 text-[15px] relative top-[2px] capitalize">{it}</label>
                      </div>
                    ))}

                  </div>

                ))}
              </>

              {renderTabsPriceRage()}

            </div>
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
            <div className="flex-1 ">
              <div className="flex-1 grid sm:grid-cols-2 xl:grid-cols-3 py-6 gap-x-8 gap-y-6 mt-3">
                {products.map((item: any, index: number) => (
                  <ProductCard refreshFnc={() => setRefreshCounter(refreshCounter + 1)} data={item} key={index} />
                ))}

              </div>
              {products?.length === 0 &&
                <div>Aucun résultat pour votre recherche <span className="font-semibold">"{location?.search.split("?q=")[1]}"</span>.
                N'abandonne pas ! Vérifie l'orthographe ou essaye avec quelque chose de plus générique.</div>
              }
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageSearch;

import { Helmet } from "react-helmet";

const Cgv = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>CGV | Au Cuir Du Lotus.fr</title>
            </Helmet>


            <div className="flex justify-center py-10">
                <div className="max-w-5xl p-4">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold mb-4">CONDITIONS GENERALES DE VENTE</h1>
                    </div>

                    <p className="mb-4 mt-14">Date de dernière mise à jour : 12/03/2022</p>


                    <div className="mt-5">
                        <h2 className="font-semibold">ARTICLE 1 - MENTION LEGALES</h2>
                        <div className="mt-2">
                            <p>Le présent site, accessible à l’URL <a href="https://au-cuir-du-lotus.fr">https://au-cuir-du-lotus.fr</a>, est édité par :</p>
                            <p>Au Cuir Du Lotus, inscrite sous le numéro 79373581200026, dont le siège social est situé au Le Renfermis 17780 Soubise ,</p>
                            <p>Le Directeur de la publication du Site est Me Pain Sabine.</p>
                            <p>Le Service Client du Vendeur peut être contacté :</p>
                            <ul className="list-disc ml-8 mt-3 mb-3">
                                <li>Au numéro suivant 05 64 58 00 05 aux horaires suivants du lundi au vendredi : 9:30-13:00 et 14:00-17:30.</li>
                                <li>Par email en vous adressant à <a href="mailto:contact@au-cuir-du-lotus.fr">contact@au-cuir-du-lotus.fr</a>.</li>
                            </ul>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h2 className="font-semibold">Article 2 – CHAMP D’APPLICATION</h2>
                        <div className="mt-2">
                            <p>Les présentes conditions générales de vente s'appliquent exclusivement à la vente des produits commercialisés par le Vendeur sur le Site à tout client ayant créé un compte.</p>
                            <p>Les Conditions Générales prévoient notamment les conditions d’achat, de paiement et de livraison des Produits commandés par le Client. Le Client peut se reporter à la description de chaque Produit figurant sur le Site afin de connaître les caractéristiques de ce dernier.</p>
                            <p>La passation d’une commande de Produits sur le Site emporte, de la part du Client, l’acceptation préalable sans restriction ni réserve des Conditions Générales, dont le Client déclare avoir pris connaissance.</p>
                            <p>Les Conditions Générales sont mises à la disposition des Clients sur le Site où elles sont directement consultables et peuvent également lui être communiquées sur simple demande par tout moyen.</p>
                            <p>Les Conditions Générales sont applicables nonobstant toute stipulation contraire figurant dans tous documents émanant du Client, et notamment dans ses conditions générales d’achat.</p>
                            <p>Les Conditions Générales sont applicables sous réserve de toute stipulation contraire figurant au bon de commande ou à des conditions particulières le cas échéant conclues entre le Vendeur et le Client concerné.</p>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h2 className="font-semibold">Article 3 – PRODUITS ET DISPONIBILITÉ</h2>
                        <div className="mt-2">
                            <p>Le prix et les caractéristiques essentielles de chaque Produit sont décrits dans une fiche technique figurant sur le Site et éditée, selon le cas, par le Vendeur ou ses fournisseurs. Cette fiche technique est susceptible de comporter des descriptions, photographies et graphismes qui ne sont communiqués qu’à titre illustratif et peuvent être modifiées/mises à jour sur le Site par le Vendeur.</p>
                            <p>Les Produits proposés sont conformes à la législation française et aux normes européennes CE en vigueur au moment de la passation de la commande.</p>
                            <p>Les offres de Produits et de prix sont valables tant qu’elles sont visibles sur le Site, dans la limite des stocks disponibles chez le Vendeur et ses fournisseurs, sauf en cas d’opérations spéciales dont la durée de validité est spécifiée sur le Site.</p>
                            <p>Le fait pour le Vendeur de présenter des Produits sur le Site ne constitue pas une obligation de commercialisation, et ce notamment en cas de rupture de stock, d’indisponibilité des Produits ou d’impossibilité de procéder à la commercialisation desdits Produits, qu’elle qu’en soit la raison.</p>
                            <p>Des informations sur la disponibilité des Produits sont communiquées au Client au moment de la passation d’une commande à titre purement indicatif (sous réserve de toute erreur de stock, commande simultanée ou détérioration de produit).</p>
                            <p>Dans le cas où un ou plusieurs Produit(s) serai(en)t indisponible(s) à la suite de la passation d’une commande, le Vendeur informera le Client par écrit du délai d’attente quant à la réception du produit momentanément indisponible. Dans cette hypothèse :</p>
                            <ul className="list-disc ml-8 mt-3 mb-3">
                                <li>le Vendeur pourra, avec l’accord du Client, proposer au même prix un produit de nature identique à celui initialement commandé ;</li>
                                <li>le Client aura le choix d’attendre la disponibilité du ou des Produits manquants afin de recevoir la globalité de sa commande en un seul envoi ;</li>
                                <li>le Client pourra annuler totalement la commande ;</li>
                                <li>le Client pourra annuler la commande des Produits indisponible(s) et recevoir ainsi les autres Produits figurant dans la commande.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 4 – CONDITIONS D’INSCRIPTION</h2>
                        <div className="mt-2">
                            <p>Tout Client désirant pleinement bénéficier du Site et procéder à l’achat de Produits devra :</p>
                            <ul className="list-disc ml-8 mt-3 mb-3">
                                <li>disposer de la pleine capacité et agir à des fins strictement professionnelles;</li>
                                <li>créer son espace personnel sur le Site en renseignant les différents champs du formulaire de création d’un compte (dénomination, numéro de RCS, nom/prénom du référent, adresse électronique, numéro de téléphone, etc.);</li>
                                <li>confirmer son acceptation des Conditions Générales;</li>
                                <li>confirmer son inscription.</li>
                            </ul>
                            <p>L’accès au Site est possible à partir d’un ordinateur, d’un smartphone ou d’une tablette en se connectant au Site.</p>
                            <p>L’utilisation du Site nécessite une connexion à internet haut débit et à internet mobile le cas échéant.</p>
                            <p>Les Clients font leur affaire personnelle de la mise en place des moyens informatiques et de télécommunication permettant l’accès au Site.</p>
                            <p>Les Services ne sont accessibles qu’à partir d’une seule connexion à la fois.</p>
                            <p>Lors de la création d’un compte avec courrier électronique, le Client est invité à choisir un mot de passe, qui constitue la garantie de la confidentialité des informations qui seront contenues dans son compte.</p>
                            <p>Aux fins de validation de l’inscription, le Vendeur adresse au Client un courrier électronique de confirmation à l’adresse email renseignée par ce dernier. Le Client procède alors à l’activation de son compte en cliquant sur le lien hypertexte prévu à cet effet dans le courrier électronique de confirmation.</p>
                            <p>Chaque Client garantit la sincérité et l'exactitude des informations fournies pour les besoins de son inscription, s'engage à lui notifier toute modification ultérieure et garantit que lesdites informations ne portent pas atteinte aux droits de tiers.</p>
                            <p>Le Client peut modifier ces informations, identifiants et mot de passe à partir de son compte sur le Site.</p>
                            <p>Le Client s’engage à ne pas divulguer ou transférer son compte, ses identifiants et mots de passe et est seul responsable de leur utilisation jusqu’à leur désactivation. Il doit informer immédiatement le Vendeur de toute perte ou utilisation non autorisée de son compte.</p>
                            <p>Le Vendeur se réserve le droit de supprimer le compte de tout Client qui aurait fourni des informations erronées.</p>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h2 className="font-semibold">Article 5 – COMMANDES</h2>
                        <div className="mt-2">
                            <p>Tout Client désirant procéder à des achats de Produits en ligne sur le Site devra :</p>
                            <ul className="list-disc ml-8 mt-3 mb-3">
                                <li>se connecter à son compte Client;</li>
                                <li>sélectionner les Produits qu’il souhaite souscrire dans son panier de Produits;</li>
                                <li>renseigner les différents champs du bon de commande Client;</li>
                                <li>confirmer son acceptation des Conditions Générales;</li>
                                <li>confirmer son acceptation de la commande des Produits;</li>
                                <li>choisir son mode de paiement;</li>
                                <li>effectuer le paiement des Produits.</li>
                            </ul>
                            <p>Sauf disposition expresse figurant sur le Site, le Client ne pourra pas modifier sa commande après l'avoir validée, laquelle sera ferme et définitive.</p>
                            <p>Dès réception du paiement des Produits compris dans la commande, le Vendeur adressera au Client un courrier électronique de confirmation de sa commande à l’adresse email renseignée par ce dernier.</p>
                            <p>Le courrier électronique de confirmation de la commande résume les caractéristiques essentielles du/des Produit(s) commandé(s), le prix total, et tout autre élément pertinent. Cet email indiquera en outre un numéro de suivi de la commande du Client.</p>
                            <p>En passant commande sur le Site, le Client accepte expressément que le Vendeur lui envoie une facture par voie électronique. Il pourra toutefois obtenir une facture papier en adressant sa demande au Service Clients.</p>
                            <p>Pour lutter contre la fraude, le Vendeur ou ses prestataires de paiement ou de livraison peuvent être amenés à demander des justificatifs supplémentaires au Client ou prendre attache avec ce dernier, au moment de l'acceptation et/ou de l’expédition de la commande. En cas de refus non justifié du Client de délivrer les informations et/ou justificatifs demandés, le Vendeur se réserve le droit de ne pas accepter ou d’annuler la commande sans que cela ne puisse faire l’objet d’une quelconque contestation.</p>
                            <p>Le Vendeur se réserve également le droit de ne pas accepter ou d’annuler la commande de tout Client qui aurait fourni des informations erronées, qui ne procèderait pas au paiement des Produits, avec lequel existerait un litige relatif au paiement d’une commande antérieure ou qui présenterait un niveau de commande anormalement élevé.</p>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h2 className="font-semibold">Article 6 – CONDITIONS TARIFAIRES</h2>
                        <div className="mt-2">
                            <p>Les Produits sont commercialisés au tarif du Vendeur en vigueur figurant sur le Site au jour de la commande, exprimé en euros TTC et hors frais.</p>
                            <p>Les tarifs sont fermes et non révisables pendant leur période de validité, les parties renonçant expressément à se prévaloir des dispositions de l’article 1195 du Code Civil.</p>
                            <p>Les éventuels frais de transport, de livraison, de traitement de la commande (port, emballage, confection du colis, services optionnels souscrits par le Client) et autres frais, intérêts et commissions sont indiqués dans le récapitulatif de la commande et sont établis en fonction du lieu et du mode de livraison sélectionnés par le Client, ainsi que du type de Produit et/ou de la quantité de Produits commandés par le Client.</p>
                            <p>Toute demande particulière du Client postérieure à la commande et engendrant des frais (emballage, transport, etc.) fera l’objet d’une facturation complémentaire au Client.</p>
                            <p>Lorsque les Produits ne sont pas réceptionnés par le Client et doivent être réexpédiés, des frais de traitement, d’expédition, de transport et de livraison supplémentaires peuvent être facturés au Client dans des conditions identiques à celles prévues lors de la commande.</p>
                            <p>Le Vendeur se réserve le droit de modifier ses prix à tout moment pour tous Produits qui seraient commandés postérieurement à cette modification.</p>
                            <p>Des éventuelles réductions de prix, ristournes et remises pourront s’appliquer aux Produits dans des conditions prévues sur le Site du Vendeur ou dans tout autre document qui serait communiqué au Client. En cas de tarif promotionnel, le Vendeur s'engage à appliquer ce tarif à toute commande passée durant la période de la promotion.</p>
                            <p>Toute modification des tarifs résultant d’une augmentation de la taxe sur la valeur ajoutée ou la création de toute nouvelle taxe assise sur le prix des Produits sera immédiatement et automatiquement appliquée.</p>
                        </div>
                    </div>



                    <div className="mt-5">
                        <h2 className="font-semibold">Article 7 – CONDITIONS DE FACTURATION ET DE PAIEMENT</h2>
                        <h3 className="font-semibold">7.1 – FACTURATION</h3>
                        <div className="mt-2">
                            <p>En cas de services spécifiques :</p>
                            <p>Les Produits sont facturés et le prix est exigible en totalité et payable comptant au jour de la commande.</p>
                            <p>En cas de règlement anticipé par le Client, aucun escompte ne sera pratiqué. En aucun cas les paiements ne peuvent être suspendus ni faire l'objet d'une quelconque compensation sans accord préalable et écrit entre le Vendeur et le Client. Toute suspension, déduction ou compensation opérée unilatéralement par le Client sera traitée comme un défaut de paiement et entraînera toutes les conséquences d’un retard de paiement.</p>
                            <p>Il est expressément convenu que le Client est valablement mis en demeure de payer par la seule exigibilité de l'obligation, conformément aux dispositions de l'article 1344 du Code civil. En cas de retard de paiement de l’une quelconque des échéances, le Vendeur se réserve notamment le droit, sans qu’aucune indemnité ne soit due au Client, de :</p>
                            <ul className="list-disc ml-8 mt-3 mb-3">
                                <li>exiger le paiement immédiat de l’ensemble des sommes à devoir au titre des Produits, celles-ci devenant immédiatement exigibles quelle que soit leur échéance initialement prévue ;</li>
                                <li>refuser toute nouvelle commande ou exiger pour toute nouvelle commande un paiement comptant ou une garantie pour la bonne exécution des engagements ;</li>
                                <li>imputer tout paiement partiel en premier lieu sur la partie non privilégiée de la créance puis sur les sommes dont l'exigibilité est la plus ancienne ;</li>
                                <li>réduire, suspendre ou annuler les commandes de Produits en cours, 14 jours après mise en demeure restée sans effet, effectuée par le Vendeur auprès du Client ;</li>
                                <li>appliquer, sans mise en demeure préalable, à toutes les sommes dues, dès le premier jour de retard et jusqu'à complet paiement, des pénalités de retard calculées au taux visé à l’article L.441-6 du Code de Commerce ; et/ou</li>
                                <li>exiger le paiement d’une indemnité forfaitaire de 40 € pour frais de recouvrement, pour chaque facture payée en retard, et le paiement d’une indemnité égale à 10% des sommes lui restant dues sur les Ventes de Produits, sans préjudice de la réparation de tout dommage effectivement subi.</li>
                            </ul>
                            <p>Le Vendeur aura la faculté de procéder à un règlement par compensation, de plein droit, et sans formalités, de toutes les sommes qu’il pourrait devoir, avec les dettes du Client à son égard, que les conditions de la compensation légale soient ou non constituées.</p>
                        </div>

                        <h3 className="font-semibold mt-4">7.2 – MODES DE PAIEMENT</h3>
                        <div className="mt-2">
                            <p>Le Client reconnaît expressément que toute commande effectuée sur le Site est une commande avec obligation de paiement, qui nécessite le paiement d’un prix des Produits dans les conditions susvisées.</p>
                            <p>Le Site utilise la solution de paiement en ligne Mangopay.</p>
                            <p>Les commandes peuvent être payées en ayant recours à l’un des modes de paiement suivants :</p>
                            <ul className="list-disc ml-8 mt-3 mb-3">
                                <li>Paiement par carte bancaire. Le paiement s'effectue directement sur les serveurs bancaires sécurisés de la banque du Vendeur, les coordonnées bancaires du Client ne transitent pas sur le Site. Les coordonnées bancaires communiquées lors du paiement sont protégées par un procédé de cryptage SSL (Secure Socket Layer). Les cartes bancaires émises par des banques domiciliées hors de France doivent obligatoirement être des cartes bancaires internationales. Le paiement via ces cartes bancaires s'effectue au comptant, sans escompte.</li>
                                <li>Paiement par chèque bancaire. Le Client peut régler sa commande par chèque bancaire s’il a un compte inscrit auprès d'une banque établie en France. Lors de la commande, le Vendeur communiquera au Client l'ordre et l'adresse où envoyer son chèque.</li>
                                <li>Paiement par virement. Le Client peut régler sa commande par virement bancaire. Lors de la commande, le Vendeur communiquera les coordonnées du compte sur lequel effectuer le virement, ainsi que la référence de commande à indiquer dans l’ordre de virement.</li>
                            </ul>
                        </div>
                    </div>





                    <div className="mt-5">
                        <h2 className="font-semibold">Article 8 – LIVRAISON DES PRODUITS</h2>
                        <h3 className="font-semibold">8.1 Livraison</h3>
                        <div className="mt-2">
                            <p>La livraison des Produits commandés par le Client ne pourra intervenir tant que celui-ci n’aura pas procédé au paiement effectif du montant visé à l’article 6.1 ci-avant.</p>
                            <p>Les Produits peuvent être livrés dans les zones géographiques suivantes : France métropolitaine.</p>
                            <p>Dès confirmation de la commande et paiement par le Client, les Produits seront expédiés selon le mode, à l’adresse et dans le délai de livraison figurant dans le récapitulatif de commande avec la facture correspondante.</p>
                            <p>Le Client s’engage à cet égard à tout mettre en œuvre pour assurer la bonne réception des Produits au jour de la livraison. Le coût afférent à toute nouvelle livraison sera supporté par le Client. Par ailleurs, en cas d’erreur de livraison consécutive à la communication d’informations inexactes par le Client (lieu de livraison, accessibilité du lieu ou tout autre problème) entraînant la nécessité d’effectuer une nouvelle livraison, les frais y afférents seront facturés au Client.</p>
                            <p>En cas d’accord préalable du Vendeur, le Client pourra procéder à l’enlèvement des Produits commandés au lieu indiqué par le Vendeur.</p>
                            <p>Les délais de livraison prévus lors des commandes ne sont donnés qu'à titre indicatif en fonction de la disponibilité des Produits commandés et des délais de livraison appliqués par les partenaires du Vendeur.</p>
                            <p>Les retards de livraison, pour autant qu’ils soient raisonnables, ne peuvent donner lieu à un quelconque droit d'annuler la vente, de refuser la marchandise ou de réclamer des dommages et intérêts. En cas de retard supérieur à 30 jours, le Client sera toutefois en droit de demander la résolution de la vente, les acomptes déjà versés lui seront restitués par le Vendeur.</p>
                            <p>En cas de livraison hors de la France métropolitaine, le Client sera considéré comme l’importateur des Produits et tenu de respecter la réglementation du pays de livraison, étant précisé que les livraisons transfrontalières peuvent le cas échéant être soumises à une procédure d’ouverture et d’inspection par les autorités douanières.</p>
                        </div>

                        <h3 className="font-semibold mt-4">8.2 Conformité de la livraison</h3>
                        <div className="mt-2">
                            <p>Le nombre et l'état des Produits doivent être vérifiés par le Client au moment de la livraison.</p>
                            <p>Le Vendeur sera tenu de reprendre les Produits en cas de livraison de Produits non-conformes ou détériorés à la livraison.</p>
                            <p>Le Client doit faire constater par écrit contradictoire auprès du transporteur les éventuels défauts apparents, produits manquants ou avaries par des réserves claires, précises et complètes sur le bon de livraison. Le Client devra confirmer sa réclamation auprès du Vendeur et du transporteur par lettre recommandée avec avis de réception dans un délai de 14 jours à compter de la livraison de la marchandise.</p>
                            <p>Le Vendeur validera la demande de retour et transmettra au Client par courrier électronique un bon de retour à joindre à l’expédition des Produits.</p>
                            <p>Le Client devra réexpédier le Produit non-conforme au Vendeur dans les meilleurs délais.</p>
                            <p>Sauf si la responsabilité exclusive du Vendeur est incontestablement établie ou si elle est expressément reconnue par le Vendeur, les frais et risques de retour seront à la charge du Client. Le Vendeur se réserve le droit de refuser le retour si les Produits ne sont pas dans leur état d’origine.</p>
                            <p>A défaut pour le Client de respecter la procédure susvisée, le Client sera présumé avoir renoncé à toute action à l’encontre du Vendeur et/ou du transporteur et avoir reçu les marchandises conformes et en bon état. Le Vendeur n’acceptera en conséquence aucune réclamation, retour ou demande d’avoir du Client.</p>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h2 className="font-semibold">Article 9 – PROPRIÉTÉ ET RISQUES</h2>

                        <h3 className="font-semibold">9.1 - Réserve de propriété</h3>
                        <div className="mt-2">
                            <p>Par dérogation aux dispositions de l'article 1583 du Code Civil, il est expressément convenu avec le Client que le transfert de la propriété des Produits livrés est suspendu jusqu'au paiement intégral et effectif du prix en principal, intérêts, taxes et tous frais accessoires. La remise des traites, de chèques bancaires ou de tout autre titre créant une obligation de payer ne constitue pas un paiement effectif à cet égard.</p>
                            <p>L'acceptation des livraisons ou des documents afférents à ces livraisons vaut acceptation de la présente clause.</p>
                            <p>Tant que les Produits n’appartiennent pas au Client, il lui est interdit d'en disposer, notamment de les donner en gage, les échanger, les transférer en propriété à titre de garantie. Toutefois, à titre de tolérance et pour les seuls besoins de son activité, le Vendeur autorise le Client à revendre les Produits. Le Client s'engage en conséquence à informer ses clients, revendeurs professionnels, de l'existence de la clause de réserve de propriété pesant sur les Produits et du droit que se réserve le Vendeur de revendiquer, entre leurs mains, soit les Produits litigieux, soit le prix.</p>
                            <p>Le Client s'oblige également, en contrepartie, à réaliser non seulement en fin d'exercice mais d'une manière permanente, par tous moyens à sa convenance, l'identification des Produits en réserve de propriété. Le Client devra faire figurer à l'actif de son bilan les Produits faisant l'objet de la réserve de propriété. Les Produits en instance de vente sont présumés impayés à concurrence de la créance du vendeur selon la règle « premier entré, premier sorti » (FIFO), i.e. que tout paiement par le Client sera affecté aux Produits les plus anciens et ce seront les Produits objet de la dernière livraison qui seront toujours considérés comme assortis de la réserve de propriété.</p>
                            <p>Nonobstant le fait que le Client ne disposera de la propriété des Produits qu’à compter du paiement effectif, le transfert des risques des Produits au Client interviendra dans les conditions prévues ci-après. Le Client s'engage en conséquence à apporter tous ses soins à la garde et à la conservation desdites marchandises.</p>
                            <p>Le Client avertit immédiatement le Vendeur par tout moyen assurant une communication parfaite de tout fait de nature à compromettre son droit de propriété, notamment de l’ouverture d’une procédure de sauvegarde, de liquidation ou de redressement judiciaire, de saisie ou de toute autre mesure conservatoire. En cas d’ouverture d’une procédure de sauvegarde, de liquidation ou de redressement judiciaire, le Client s’engage à participer à l’établissement d’un inventaire des Produits se trouvant dans ses stocks et dont le Vendeur revendique la propriété ou le paiement et à l’assister efficacement dans la procédure de revendication introduite auprès des autorités compétentes. En cas de saisie ou de toute autre mesure conservatoire sur les Produits livrés par le Vendeur, le Client élèvera toutes protestations à l’égard du tiers et prendra toutes mesures conservatoires et d’exécution.</p>
                            <p>Tous les frais légaux et judiciaires générés par la récupération des Produits sous réserve de propriété ou de leur prix seront supportés par le Client.</p>
                        </div>

                        <h3 className="font-semibold mt-4">9.2 - Transfert des risques</h3>
                        <div className="mt-2">
                            <p>Les Produits seront aux charges, risques et périls du client à compter de leur remise au transporteur ou de leur enlèvement par le Client.</p>
                            <p>Dans ce cas de figure, le Vendeur sera réputé avoir rempli son obligation de délivrance dès lors qu'il a remis les Produits au transporteur et que ce dernier les a acceptés sans réserve, le Client ne disposant d'aucun recours en garantie contre le Vendeur en cas de défaut de livraison ou défaut de conformité des Produits lors de la livraison.</p>
                            <p>Le Client souscrira en conséquence tous contrats d'assurances utiles garantissant les risques de perte, destruction ou vol des marchandises dont les risques ont été transférés.</p>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h2 className="font-semibold">Article 10 – GARANTIE</h2>
                        <div className="mt-2">
                            <p>Tout retour de Produits défectueux ou pour vice de matière, de conception ou de fabrication nécessite l’accord préalable et écrit du Vendeur. Sauf si la responsabilité du Vendeur est incontestablement établie ou si elle est expressément reconnue par le Vendeur, les frais et risques de retour seront à la charge du Client. Le Vendeur se réserve le droit de refuser le retour si les Produits ne sont pas dans leur état d’origine.</p>
                            <p>Aucun retour de Produits pour invendu ne sera accepté.</p>
                            <p>Tout Produit défectueux pourra faire l’objet d’une réparation, d’un remplacement par un produit identique ou équivalent, ou d’un remboursement, selon la solution que le Vendeur ou les fabricants concernés estimeront la plus adaptée, sauf en cas :</p>
                            <ul className="list-disc ml-8 mt-3 mb-3">
                                <li>d’altération ou de modification des Produits ;</li>
                                <li>d’usage ou d'utilisation anormale ou non conforme des Produits ;</li>
                                <li>de défauts et conséquences liés à l'utilisation non conforme à l'usage pour lequel les Produits sont destinés ;</li>
                                <li>de défauts et conséquences liés à toute cause extérieure ;</li>
                                <li>d’usure normale des Produits ;</li>
                                <li>de non-respect de la procédure de retour par le Client.</li>
                            </ul>
                            <p>En cas de litige sur la prise en garantie d’un Produit, les parties devront faire leurs meilleurs en vue de parvenir à un règlement amiable de la situation.</p>
                            <p>A défaut de règlement amiable et en cas d’inexécution suffisamment grave du Vendeur, le Client pourra résilier de plein droit la vente et obtenir le cas échéant des dommages et intérêts du Vendeur aux fins de réparer le préjudice subi, le Client renonçant par avance à solliciter une exécution forcée en nature de Produits par le Vendeur ou un tiers ou une réduction proportionnelle du prix, par dérogation expresse aux dispositions des articles 1221, 1222 et 1223 du Code civil.</p>
                        </div>
                    </div>



                    <div className="mt-5">
                        <h2 className="font-semibold">Article 11 – DISPONIBILITÉ DU SITE</h2>
                        <div className="mt-2">
                            <p>Le Vendeur s’efforce d’assurer l’accès et le bon fonctionnement du Site vingt-quatre heures sur vingt-quatre, sept jours sur sept.</p>
                            <p>Le Vendeur ne peut cependant exclure que l’accès et le fonctionnement du Site soient interrompus notamment en cas de force majeure, de dysfonctionnements des équipements ou du réseau internet des Clients, de défaillance des opérateurs de télécommunication, d’interruption de fourniture d’électricité, d’utilisation anormale, illicite ou frauduleuse du Site par un Client ou un tiers, de décision des autorités compétentes, ou pour tout autre motif.</p>
                            <p>Le Vendeur se réserve également le droit d'apporter au Site et aux Services toutes les modifications et améliorations de son choix liées à l'évolution technique ou au bon fonctionnement.</p>
                            <p>Les interruptions générales et temporaires du Site seront, dans la mesure du possible, notifiées via le Site avant qu’elles n’interviennent, sauf lorsque ces interruptions ont un caractère d’urgence.</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 12 - RESPONSABILITÉ</h2>
                        <div className="mt-2">
                            <p>La responsabilité du Vendeur ne pourra être engagée dans le cas où l’inexécution de ses obligations serait imputable au Client, au fait imprévisible et insurmontable d’un tiers au contrat ou à un cas de force majeure au sens de l’article 1218 du Code Civil, y compris, mais sans y être limités, les évènements imprévisibles tels que grèves, arrêts de travail, troubles sociaux, fermetures d’usines, inondations, incendies, défaut de production ou de transport non-consécutif à son fait personnel, rupture d’approvisionnement, guerres, émeutes, insurrections et plus généralement toute circonstance ou événement empêchant le Vendeur d’exécuter convenablement ses obligations.</p>
                            <p>Le Vendeur n’encourt aucune responsabilité au titre de tous dommages ou préjudices indirects ou immatériels tels que préjudice financier, perte de chance, perte de profit, perte de contrat, perte de commande, perte de clientèle, perte d’exploitation, préjudice ou trouble commercial ou préjudice d’image, qui pourraient résulter de la livraison de Produits non-conformes ou défectueux ou de l’absence de livraison des Produits.</p>
                            <p>La vente de Produits et les présentes Conditions Générales étant conformes à la législation française, la responsabilité du Vendeur ne saurait être engagée en cas de non-respect de la législation d'un autre pays dès lors que le Client effectue une commande de Produit à partir d’un autre pays.</p>
                            <p>En tout état de cause et dans tous les cas de retour, si la responsabilité du Vendeur devait être retenue, elle ne pourrait en aucun cas aller au-delà du prix d’achat de la marchandise payée par le Client.</p>
                            <p>Conformément aux dispositions de l’article 2254 du Code Civil, toute action judiciaire d’un Client à l’égard du Vendeur est atteinte par la prescription à l’expiration d’une durée d’un (1) an suivant la date à laquelle le Client concerné a eu connaissance ou est présumé avoir eu connaissance du fait dommageable.</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 13 - SYSTÈMES D’ENREGISTREMENT</h2>
                        <div className="mt-2">
                            <p>Les registres informatisés, conservés dans les systèmes informatiques du Vendeur et de ses partenaires dans des conditions raisonnables de sécurité, seront considérés comme les preuves des communications et actions des Clients et du Vendeur. L’archivage de ces éléments est effectué sur un support fiable et durable de manière à correspondre à une copie fidèle et durable au sens de la réglementation applicable.</p>
                            <p>Chaque Client reconnaît la valeur de preuve des systèmes d’enregistrement automatisés du Site et déclare renoncer à les contester en cas de litige.</p>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h2 className="font-semibold">Article 14 - DONNÉES PERSONNELLES</h2>
                        <div className="mt-2">
                            <p>Pour davantage d’informations concernant l’utilisation de données à caractère personnel par le Vendeur, veuillez lire attentivement la Charte sur le respect de la vie privée (la « Charte »). Vous pouvez à tout moment consulter cette Charte sur le Site.</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 15 - LIENS HYPERTEXTES</h2>
                        <div className="mt-2">
                            <p>Les liens hypertextes disponibles sur le Site peuvent renvoyer vers des sites tiers ou partenaires. Ils sont fournis uniquement pour la convenance du Client, afin de faciliter l’utilisation des ressources disponibles sur l’Internet. Si le Client utilise ces liens, il quittera le Site et acceptera alors d’utiliser les sites tiers à ses risques et périls ou le cas échéant conformément aux conditions qui les régissent.</p>
                            <p>En tout état de cause, l’existence d’un lien hypertexte vers le Site en provenance d’un site tiers ou sur le Site vers un site tiers ou de partenaire ne saurait engager la responsabilité du Vendeur à quelque titre que ce soit et notamment quant à la disponibilité, aux contenus et aux produits et/ou services disponibles sur ou à partir de ce site tiers ou partenaire.</p>
                            <p>Le Client n’est pas autorisé à créer sur un site tiers un ou plusieurs liens hypertextes reliant à la page d’accueil du Site ou vers sa page profil, sauf autorisation préalable et écrite du Vendeur.</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 16 - PROPRIÉTÉ INTELLECTUELLE</h2>
                        <div className="mt-2">
                            <p>Le Vendeur est seul titulaire de tous les contenus présents sur le Site, notamment et sans limitation, tous textes, fichiers, images animées ou non, photographies, vidéos, logos, dessins, modèles, logiciels, marques, identité visuelle, base de données, structure du Site et tous autres éléments de propriété intellectuelle et autres données ou informations qui sont protégés par les lois et règlements français et internationaux relatifs notamment à la propriété intellectuelle.</p>
                            <p>En conséquence, aucun des Contenus du Site ne pourra en tout ou partie être modifié, reproduit, copié, dupliqué, vendu, revendu, transmis, publié, communiqué, distribué, diffusé, représenté, stocké, utilisé, loué ou exploité de toute autre manière, à titre gratuit ou onéreux, par un Client ou par un tiers, quel que soient les moyens et/ou les supports utilisés, qu’ils soient connus ou inconnus à ce jour, sans l’autorisation préalable et écrite du Vendeur, et le Client est seul responsable de toute utilisation et/ou exploitation non autorisée.</p>
                            <p>En outre, toute extraction, intégration, compilation, ou utilisation à des fins commerciales d’informations contenues dans les bases de données accessibles sur le Site, ainsi que toute utilisation de logiciels, robots, systèmes d’exploration de données et autres outils de collecte de données est strictement interdite aux Clients.</p>
                            <p>Le Vendeur concède toutefois aux Clients, sous réserve du respect par ces derniers des présentes Conditions Générales, un droit non exclusif et non cessible d’accéder aux contenus présents sur le Site dont il détient la pleine propriété, de les télécharger et de les imprimer dans le cadre d’une utilisation personnelle et non commerciale.</p>
                            <p>Le Vendeur est susceptible d’être titulaire de droits propriété intellectuelle et/ou industrielle concernant les Produits vendus au Client. Dès lors que le Client aurait connaissance d’une contrefaçon des droits de propriété intellectuelle et/ou industrielle du Vendeur, il devra en informer immédiatement le Vendeur par écrit.</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 17 - DUREE – SUSPENSION - RESILIATION</h2>
                        <div className="mt-2">
                            <p>En cas de vente ponctuelle ou en application de conditions particulières, les présentes Conditions Générales sont applicables pour la durée de chaque opération de vente de Produits réalisée entre le Vendeur et un Client ou, le cas échéant, pour la durée des conditions particulières conclues avec un Client.</p>
                            <p>Le Vendeur se réserve le droit de suspendre l’accès d’un Client au Site de façon permanente ou de façon temporaire, en cas de manquement par ledit Client à ses obligations résultant des présentes Conditions Générales.</p>
                            <p>Par ailleurs, le Vendeur ou le Client pourra résilier les Conditions Générales de plein droit de manière anticipée par l’envoi d’une notification écrite :</p>
                            <ul className="list-disc ml-6">
                                <li>en cas de survenance d’un cas de force majeure tel que visé à l’article 11 ;</li>
                                <li>après avoir notifié l’autre partie en cas de manquement grave de celle-ci à ses obligations ou au titre des lois et règlements applicables, auquel il n’aurait pas été remédié dans un délai de quinze (15) jours (lorsqu’il peut être remédié à ce manquement) suivant notification écrite indiquant la nature du manquement et la nécessité d’y remédier.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 18 - CONFIDENTIALITÉ</h2>
                        <div className="mt-2">
                            <p>Pendant la durée des présentes, chaque partie pourra prendre connaissance ou recevoir des informations, documents et/ou données confidentiels à propos de l’autre partie. De ce fait, chaque partie s’engage, tant en son nom qu’au nom de ses préposés pour lesquels elle se porte fort, à préserver la stricte confidentialité de toutes les informations, documents et/ou données confidentiels de toute nature en rapport avec les résultats, l’activité ou la clientèle de l’autre partie ou toute information reçue ou obtenue d’une partie dans le cadre des relations contractuelles établies.</p>
                            <p>Le présent engagement de confidentialité des parties est valable, tant pour la durée des présentes que pendant une durée de deux (2) ans suivant leur expiration ou résiliation.</p>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h2 className="font-semibold">Article 19 - NOTIFICATIONS</h2>
                        <div className="mt-2">
                            <p>Toute notification ou convocation écrite requise ou permise en vertu des stipulations des présentes sera valablement effectuée si elle est adressée par lettre remise en main propre ou par porteur contre reçu de livraison, par courrier recommandé avec avis de réception, ou par courrier électronique (sauf en cas de résiliation des présentes), adressé aux coordonnées de la partie concernée, chaque partie élisant domicile en son siège social.</p>
                            <p>Tout changement de coordonnées d’une partie pour les besoins des présentes devra être notifié à l’autre partie selon les modalités prévues ci-dessus.</p>
                            <p>Les notifications adressées en mains propres ou par porteur seront présumées avoir été faites à leur date de remise au destinataire, telle qu'attestée par le reçu de livraison. Les notifications faites par courrier recommandé avec avis de réception seront présumées avoir été faites à la date de leur première présentation à l'adresse du destinataire. Les notifications faites par courrier électronique seront présumées avoir été faites à la date d'envoi du courrier électronique.</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 20 - AUTONOMIE ET ABSENCE DE RENONCIATION</h2>
                        <div className="mt-2">
                            <p>Si l'une quelconque des stipulations des présentes Conditions générales était déclarée nulle ou inapplicable pour quelque cause que ce soit en application d'une loi, d'un règlement ou à la suite d'une décision de justice devenue définitive, elle serait réputée non écrite et les autres stipulations resteraient en vigueur.</p>
                            <p>Le fait pour le Vendeur de ne pas se prévaloir à titre temporaire ou permanent d’une ou de plusieurs stipulations des Conditions Générales n’emportera en aucun cas renonciation.</p>
                        </div>
                    </div>



                    <div className="mt-5">
                        <h2 className="font-semibold">Article 21 - MODIFICATION</h2>
                        <div className="mt-2">
                            <p>Le Vendeur se réserve le droit de modifier à tout moment et sans préavis le contenu ou la localisation du Site et les présentes Conditions Générales.</p>
                            <p>Toute utilisation du Site ou commande consécutive à une modification apportée aux Conditions Générales emportera acceptation par chaque Client desdites modifications. La version la plus récente et en vigueur des Conditions Générales sera toujours disponible à l’adresse suivante : <a href="https://www.legalplace.fr">https://www.legalplace.fr</a>.</p>
                            <p>Lorsque les modifications apportées aux Conditions Générales seront considérées par comme substantielles, celles-ci pourront être portées à la connaissance des Clients par courrier électronique et devront faire l’objet d’une acceptation par ces derniers lors de leur prochaine connexion sur le Site.</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 22 - LITIGES</h2>
                        <div className="mt-2">
                            <p>Les litiges qui pourront survenir dans le cadre des relations contractuelles établies entre le Client et le Vendeur devront être résolus, dans la mesure du possible, de manière amiable.</p>
                            <p>A défaut de règlement amiable dans un délai d’un mois à compter de la saisine de l’une des parties, tous les litiges auxquels les Conditions Générales pourraient donner lieu, concernant tant leur validité, interprétation, exécution, résiliation, leurs conséquences et leurs suites, seront soumis au tribunal de Bayonne.</p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h2 className="font-semibold">Article 23 - DROIT APPLICABLE & LANGUE DU CONTRAT</h2>
                        <div className="mt-2">
                            <p>Les présentes Conditions Générales et les opérations qui en découlent sont régies et soumises au droit français. Elles sont rédigées en langue française. En cas de traduction en une ou plusieurs langues étrangères, seul le texte français fera foi en cas de litige.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Cgv;

import React, { FC, useEffect, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import { Product } from "../models/Product";
import { useLocation } from "react-router-dom";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  heading,
  data,
}) => {
  const sliderRef = useRef(null);
  const id = "651d3c0cfbe7049c789be667"; // The desired ID
  const UNIQUE_CLASS = "glidejs_" + id.replace(/:/g, "_");
  const location = useLocation();

  // Filter products based on the desired ID in collections
  const filteredData = data?.filter((item) =>
    item?.collections?.includes(id)
  );

  useEffect(() => {
    if (!sliderRef.current) {
      return () => { };
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS]);

  return (
    <>
      {filteredData?.length !== 0 && (
        <div className={`nc-SectionSliderProductCard ${className} relative pb-6`}>
          <div className={`${UNIQUE_CLASS} container flow-root`} ref={sliderRef}>
            <Heading isCenter={false} hasNextPrev className="lg:mb-6 mt-10">
              <span className={`mt-12 text-[#7c6555] text-[44px] font-marck`}>{heading}</span>
            </Heading>
            <div className="glide mt-6 md:mt-0 lgm:mt-0 xl:mt-0">
              <div data-glide-el="track" className="glide__track">
                <ul className="glide__slides">
                  {filteredData?.map((item: Product, index: number) => (
                    <li className="glide__slide m-1" key={index}>
                      <ProductCard data={item} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SectionSliderProductCard;

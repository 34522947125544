import React, { FC, useEffect, useState } from "react";
import Logo from "images/logo.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CartDropdown from "./CartDropdown";
import { getAllCategories } from "actions/categories";
import NavigationItem from "shared/Navigation/NavigationItem";
import { BORDERS, COLORS } from "config/theme";
import MenuBar from "shared/MenuBar/MenuBar";
import { NotificationManager } from 'react-notifications';
import NcImage from "shared/NcImage/NcImage";
import ButtonThird from "shared/Button/ButtonThird";

const MainNav: FC = () => {
  let prevPathname = useLocation().pathname;
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState('');
  const history: any = useHistory();

  const handleRedirectSearch = () => {
    if (query !== '') {
      setShowSearchForm(false)
      history.replace({ pathname: "/search", search: 'q=' + query })
    } else {
      NotificationManager.error('Votre recherche ne comporte aucun mot clés !', '')
    }
  }

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Ajoutez un écouteur d'événement pour le changement de route
    const unlisten = history.listen(handleScrollToTop);

    // Nettoyez l'écouteur d'événement lors du démontage du composant.
    return () => {
      unlisten();
    };
  }, [history]);


  useEffect(() => {
    window.scrollTo(0, 0); // Positionne l'utilisateur en haut de la page à chaque changement de route
  }, [history.location.pathname]);

  useEffect(() => {
    getAllCategories().then((result: any) => {
      setCategories(result);
    })
  }, [])

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke="white"
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="white"

          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <div
        className="flex items-center justify-center w-full"
      >
        <div className={`dark:bg-slate-800 flex items-center space-x-1.5 px-5 box-shadow-searchbar w-full border-[1px] border-[${COLORS?.searchInputBorder}]`} style={{ borderRadius: BORDERS.searchInput }}>
          {renderMagnifyingGlassIcon()}
          <input
            onKeyUp={(e) => e.key === 'Enter' && handleRedirectSearch()}
            ref={inputRef}
            onChange={(e) => setQuery(e.target.value)}
            type="text"
            placeholder="Rechercher un produit..."
            className={`border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base text-[white] placeholder:text-[white]`}
            autoFocus
          />
          <button onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5 text-[white]" />
          </button>
        </div>
      </div>
    );
  };


  const renderCategoriesNavBar = (item: any) => Object.keys(item).map((item: any, index) => {
    return (
      <ul key={index} className="nc-Navigation flex justify-center items-center uppercase text-[15px]">
        <NavigationItem key={item?._id} menuItem={categories[item]} type="megaMenu" />
        <li className="text-[#7c6555] mr-5 cursor-pointer font-[500] hover:font-[600]" onClick={() => history.push({ pathname: '/page/mon-histoire' })}>Mon histoire</li>
        <li className="text-[#7c6555] mr-5 cursor-pointer font-[500] hover:font-[600]" onClick={() => history.push({ pathname: '/page/creations-personnalisees' })}>Créations personnalisées</li>
        <li className="text-[#7c6555] cursor-pointer font-[500] hover:font-[600]" onClick={() => history.push({ pathname: '/contact' })}>Me contacter</li>
      </ul>
    )
  })

  return (
    <>
      <div className={`nc-MainNavLogged relative z-10`} style={{ backgroundColor: COLORS?.navbar }}>
        <div className="container">
          <div className="h-20 flex justify-between">
            <div className="flex items-center lg:hidden">
              <MenuBar />
            </div>

            <div className={`flex items-center justify-center`}>
              <img onClick={() => history.push({ pathname: '/' })} src={Logo} className="w-[58px] cursor-pointer" />
            </div>

            {showSearchForm
              ? <div className="flex-[2] hidden lg:flex justify-center mx-4">
                {renderSearchForm()}
              </div>
              : <div className="hidden lg:flex lg:justify-center mx-4">
                {renderCategoriesNavBar(categories)}</div>
            }

            <div className="flex items-center justify-end text-slate-700 dark:text-slate-100">

              <CartDropdown />

              <Link
                className="ml-3 md:hidden sm:hidden xs:hidden"
                to={{ pathname: "/login", state: { from: prevPathname } }}>
                <ButtonThird>Connexion</ButtonThird>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNav;

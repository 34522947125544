export const COLORS = {


    preHeader: "#F7ECDB",
    preHeaderText: "#7c6555",

    // NAVBAR 
    navbar: '#dacdc5',
    navbarText: 'black',
    navbarTextHover: '#1e6132',
    navbarArrow: '#7c6555',


    // SLIDER
    sliderText: 'red',
    sliderBg: 'white',
    sliderBgText: "white",

    // FOOTER
    footer: '#edebe2',
    footerText: '#121212',


    // BUTTON
    buttonPrimary: '#7c6555',
    buttonSecondary: '#7c6555',
    buttonThird: '#7c6555',
    buttonFour: 'white',
    filterBtn: '#6E5D4A',

    // INPUT
    searchInputBorder: 'white',

    heading: '#7c6555',
    text: '#7c6555',
    headingCaroussel: "#7c6555",
    paragraphCaroussel: "#7c6555",
    placeholderSearchInput: 'white',
    backgroundHowItWork: '#edebe2',
    loadingSpinner: '',
}

export const BORDERS = {
    buttonPrimary: '0px',
    buttonSecondary: '0px',
    buttonVariant: '1px',
    searchInput: '999px',
    inputQuantityNumber: '0px',
    buttonThird: '3px',
    buttonFour: '3px',
    input: ''
}

import { FC } from "react";
import HIW1 from "images/handcraft.svg";
import HIW2 from "images/wallet.svg";
import HIW3 from "images/truck.svg";
import HIW4 from "images/france.svg";
import { COLORS } from "config/theme";

export interface SectionHowItWorkProps {
  className?: string;
  data?: typeof DEMO_DATA[0][];
}

const DEMO_DATA = [
  {
    id: 1,
    icon: HIW1,
    title: "Créations 100% fait main",
  },
  {
    id: 2,
    icon: HIW2,
    title: "Paiement sécurisé",
  },
  {
    id: 3,
    icon: HIW3,
    title: "Livraisons rapide partout en France",
  },
  {
    id: 4,
    icon: HIW4,
    title: "Service client basé en France",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div style={{ backgroundColor: COLORS?.backgroundHowItWork }}>
      <div
        className={`nc-SectionHowItWork container ${className}`}
        data-nc-id="SectionHowItWork"
      >
        <div className="p-4 flex grid lg:grid-cols-4 xs:grid-cols-2 md:grid-cols-4 sm:grid-cols-2 gap-8 items-center justify-center">
          {data.map((item: typeof DEMO_DATA[number], index: number) => (
            <div key={index} className="flex justify-center items-center">
              <div className="mr-4">
                <img className="hitImg" src={item?.icon} />
              </div>
              <div className={`max-w-[160px] text-[#7c6555] font-[500]`}>
                {item?.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionHowItWork;

import React, { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ModalQuickView from "./ModalQuickView";
import { Product } from "../models/Product"
import PlaceIcon from "shared/NcImage/PlaceIcon";
import { addProductInWishlist, removeProductInWishlist } from "actions/wishlist";
import { useDispatch, useSelector } from "react-redux";
import { IMG_LINK } from "config/key";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface ProductCardProps {
  className?: string;
  data: any;
  refreshFnc?: Function
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
  refreshFnc
}) => {
  const {
    name,
    Variants,
    _id,
    defaultVariantId,
  } = data;

  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { wishlist }: any = useSelector(state => state);
  const { auth }: any = useSelector(state => state)
  const [defaultVariant, setDefaultVariant] = useState<any>([]);
  const [dataQuickView, setDataQuickView] = useState<any>([]);

  useEffect(() => {

    var defaultVariant = Variants?.find((item: any) => item._id == defaultVariantId);
    setDefaultVariant(defaultVariant);

  }, [refreshFnc])

  const handleAddProductWishlist = async (itemId: any) => {
    if (!wishlist.hasOwnProperty(_id)) {
      await dispatch(addProductInWishlist(itemId));
    } else {
      await dispatch(removeProductInWishlist(itemId)).then(() => {
        if (refreshFnc) {
          refreshFnc()
        }
      })
    }
  }

  const handleQuickViewProduct = (data: Product) => {
    setDataQuickView(data)
  
  
    if (!showModalQuickView) {
      setShowModalQuickView(true)
    }
  }

  const minPrice = Variants && Math.min(...Variants.map((product: any) => product?.rebatedPrice || product.price));
  const maxPrice = Variants && Math.max(...Variants.map((product: any) => product.price));

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className} bg-[white] p-1 shadow-lg hover:shadow-lg mb-6`}
        data-nc-id="ProductCard"
      >
        <Link to={{ pathname: "/product-detail/" + data.slug }} className="absolute inset-0"></Link>

        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 overflow-hidden z-1 group">
          

          <Link to={{ pathname: "/product-detail/" + data.slug }} className="block">
            <div className="absolute top-4 z-[25] left-2">
              {defaultVariant?.rebatedPrice &&
              <span className={`flex flex-row ml-2 px-1 text-[12px] font-semibold rounded bg-[#7c6555] text-[white]`}>
              -{Math.round((defaultVariant.price - defaultVariant.rebatedPrice) / defaultVariant.price * 100)} %
              </span>
            }
            </div>

            {defaultVariant?.images?.image1Id

              ? <div className="flex aspect-w-11 aspect-h-12 w-full h-0">
                <img
                  src={IMG_LINK + defaultVariant?.images?.image1Id}
                  className="object-cover w-full h-full drop-shadow-xl"
                />
              </div>

              : <div className="nc-NcImage flex aspect-w-11 aspect-h-12 w-full h-0">
                <div className={`${className} flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                  <div className="h-2/4 max-w-[50%]">
                    <PlaceIcon />
                  </div>
                </div>
              </div>
            }
          </Link>

          <div className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">

            <ButtonSecondary
              className="ml-1.5 transition-colors shadow-lg"
              fontSize="text-xs"
              sizeClass="py-2 px-4"
              onClick={() => handleQuickViewProduct(data)}
            >
              <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
              <span className="ml-1">Aperçu rapide</span>
            </ButtonSecondary>
          </div>

          {auth?.token !== undefined &&
            <>
              {auth?.token !== null &&
                <div onClick={() => handleAddProductWishlist(data?._id)} className="flex absolute top-1 right-1 p-2">
                  <button className={`w-9 h-9 flex items-center justify-center rounded-full bg-white dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ${className}`}>
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                        stroke={wishlist.hasOwnProperty(data._id) ? 'red' : "currentColor"}
                        fill={wishlist.hasOwnProperty(data._id) ? 'red' : "white"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              }
            </>
          }
        </div>

        <hr className="mt-3 mb-3" />

        <div className="space-y-2 px-2.5 pb-2.5">
          <div>
            <h2
              className={`nc-ProductCard__title text-base font-[500] transition-colors text-center`}
            >
              {name}
            </h2>




            <div className="">



              <div className="mb-4 flex justify-center">
                {Variants &&
                  <>
                    {minPrice !== maxPrice
                      ? <div className="flex flex-row">
                        {data.simpleItem === false
                          ? <div className="flex flex-row justify-center mt-4">
                            <span className="text-[13px] text-center">À partir de</span>
                            <Prices price={minPrice} />
                            {"-"}
                            <Prices price={maxPrice} />
                          </div>
                          : <>
                            {defaultVariant?.rebatedPrice
                              ? <>
                                <Prices
                                  price={defaultVariant.rebatedPrice}
                                />

                                <Prices
                                  className="text-[red]"
                                  contentClass="py-1 md:py-1.5 md:px-3 text-lg font-[500] line-through text-red-500"
                                  price={defaultVariant.price}
                                />
                              </>

                              : <Prices
                                price={defaultVariant.price}
                              />
                            }
                          </>
                        }
                      </div>
                      : <Prices
                      price={defaultVariant.price}
                    />
                    }

                  </>
                }
              </div>
            </div>



          </div>

          <div className="flex w-full mt-5">
            <ButtonPrimary
              className="shadow-lg flex w-full"
              fontSize="text-sm"
              sizeClass="py-[8px] px-4"
              onClick={() => history.push({ pathname: '/product-detail/' + data?.slug })}
            >
              <div className="font-semibold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="18px" height="18px" viewBox="0 0 32 32">
                  <path d="M 19 3 C 13.488281 3 9 7.488281 9 13 C 9 15.394531 9.839844 17.589844 11.25 19.3125 L 3.28125 27.28125 L 4.71875 28.71875 L 12.6875 20.75 C 14.410156 22.160156 16.605469 23 19 23 C 24.511719 23 29 18.511719 29 13 C 29 7.488281 24.511719 3 19 3 Z M 19 5 C 23.429688 5 27 8.570313 27 13 C 27 17.429688 23.429688 21 19 21 C 14.570313 21 11 17.429688 11 13 C 11 8.570313 14.570313 5 19 5 Z M 18 9 L 18 12 L 15 12 L 15 14 L 18 14 L 18 17 L 20 17 L 20 14 L 23 14 L 23 12 L 20 12 L 20 9 Z" />
                </svg>
              </div>
              <span className="ml-2">Découvrir</span>
            </ButtonPrimary>
          </div>

        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView
        data={dataQuickView}
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;

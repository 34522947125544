
import { Helmet } from "react-helmet";
import img5 from "../../images/img5.png"
import atelier1 from "../../images/Atelier.png"
import atelier2 from "../../images/Atelier_2.png"
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const MonHistoire = () => {
    const history = useHistory();

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Qui-suis je ? Une créatrice de maroquerie unique - Au Cuir Du Lotus</title>
            </Helmet>

            <div className="bg-[#edebe2] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16 text-[white]">
                    <Link to="/" className="underline cursor-pointer font-[500] text-[#7c6555]">Accueil</Link>
                    <span className="ml-2 mr-2 text-[#555]">{">"}</span>
                    <h1 className="text-[#555]">Mon  histoire</h1>
                </div>
            </div>
            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-14">

                            <div className="flex justify-center">
                                <h1 className="font-marck text-[#725E39] font-[500] text-[48px] ">Mon Histoire</h1>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-10 lg:gap-10 xl:gap-10 lg:grid-cols-3 xl:grid-cols-3 mt-16 text-[18px] leading-[30px]">
                                <div className="flex justify-center">
                                    <img className='flex h-full w-full' src={img5} />
                                </div>
                                <div className="col-span-2 font-merriweather text-[#555] font-[500]">
                                    <p>J’ai découvert le travail du cuir sur le tard, il faut bien se l’avouer. C’est après une longue période d’arrêt (opération) qui a abouti à une inaptitude pour continuer mon métier d’hydrothérapeute.</p>
                                    <p>Je me suis remise en question et c’était parti pour une réorientation professionnelle qui a abouti à la maroquinerie. J’ai commencé en autodidacte et j’ai pu faire une formation « Sellier-Maroquinier d’art option sculpture sur cuir ».</p>
                                    <h3 className="font-marck mt-3 mb-3 text-[24px]">Un savoir-faire artisanal du cuir ...</h3>
                                    <p>Je travaille le cuir tannage végétal naturel qui est le procédé le plus ancien. Il nécessite l'utilisation de tannins végétaux comme par exemple la poudre d'écorce de quebracho, de mimosa ou de châtaignier. Il est non-allergène, a des teintes naturelles qui se patineront avec le temps. Le tannage végétal permet d'avoir des cuirs plus fermes et rigides avec un toucher plus doux et soyeux.</p>
                                </div>
                            </div>


                            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-10 lg:gap-10 xl:gap-10 lg:grid-cols-3 xl:grid-cols-3 md:mt-16 lg:mt-16 xl:mt-16 text-[18px] leading-[30px]">
                                <div className="col-span-2 mt-14 md:mt-0 lg:mt-0 xl:mt-0 order-2 font-merriweather text-[#555] font-[500]">
                                    <p>Ce procédé est beaucoup plus lent qu'un tannage minéral, mais surtout plus respectueux de l'environnement. Avec ce procédé, je peux allier le dessin, le modeler, le teinter ou le laisser naturel pour qu’il patine.</p>
                                    <h3 className="font-marck mt-3 mb-3 text-[24px]">... avec une touche créative</h3>
                                    <p className="mt-3">Je travaille seule dans mon petit atelier avec une grande passion, toutes mes créations sont faites en petites quantités et une même pièce ne sera jamais identique.</p>
                                    <p className="mt-3">Je fais tout à la main, les coutures sont faites avec le point sellier (couture la plus solide). J’imagine, je crée mon dessin, je patronne, je découpe, je transfert mon dessin, je repousse, je teinte, j'encolle, je couds et ma création prend forme.</p>
                                </div>
                                <img className="flex h-full w-full order-1 md:order-last xl:order-last lg:order-last mt-16 md:mt-0 lg:mt-0 xl:mt-0" src={atelier1} />
                            </div>


                            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-10 lg:gap-10 xl:gap-10 lg:grid-cols-3 xl:grid-cols-3 md:mt-16 lg:mt-16 xl:mt-16 text-[18px] leading-[30px]">
                                <div className="col-span-2 mt-14 md:mt-0 lg:mt-0 xl:mt-0 order-2 font-merriweather text-[#555] font-[500]">
                                    <h3 className="font-marck mt-3 mb-3 text-[24px]">Une personnalisation de vos créations</h3>
                                    <p>Je prends plaisir à créer pour vous faire plaisir. Une personnalisation (prénom, choix d’un dessin, des couleurs) est possible.</p>
                                    <p className="mt-3">Je suis joignable aux heures indiquées mais étant seule, ce sont des heures où je produis. Vous pouvez me laisser un message avec votre nom et coordonnées pour que je puisse vous rappeler ou un mail. Et je vous rappelle dès que possible.</p>
                                    <p className="mt-3">J’espère que mes créations qui sont faites pour durer dans le temps, vous donneront autant de plaisir que j’ai à les faire.</p>
                                    <div className="flex justify-center mt-6">
                                        <ButtonPrimary className="font-merriweather" onClick={() => history.push({pathname: '/contact'})}>Contactez-moi</ButtonPrimary>
                                    </div>
                                </div>
                                <img className="flex h-full w-full order-1 md:order-last xl:order-last lg:order-last mt-16 md:mt-0 lg:mt-0 xl:mt-0" src={atelier2} />
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MonHistoire;
